<template>
  <div class="input-block d-flex justify-start" :class="column?'flex-column align-start':'align-center'">
    <div
      style="min-width: 80px"
      :class="column?'mb-1':'mb-5'"
    >{{ $t(title) }}</div>
    <!-- mode input-->
    <div
      class="d-flex w-full align-center"
      :class="{'mr-4':!column}"
    >
      <v-text-field
        v-if="mode==='input'"
        color="secondary"
        v-model="text"
        outlined
        dense
        full-width
        height="43"
        :type="type"
        :disabled="disabled"
        :readonly="readonly"
        :rules="rules"
        :hide-details="'auto'"
      ></v-text-field>

      <!-- mode balance-->
      <v-text-field
        v-else-if="mode==='balance'"
        v-model="text"
        outlined
        dense
        persistent-hint
        :hint="`Balance: ${balance} ${token.toUpperCase()}`"
        color="primary"
        :disabled="disabled"
        :rules="[...rules, balanceRule]"
      >
        <template v-slot:append>
          <div @click="text=balance">Max</div>
        </template>
      </v-text-field>

      <!-- mode select-->
      <v-select
        v-else-if="mode==='select'"
        v-model="text"
        outlined
        dense
        full-width
        color="secondary"
        :items="selectItems"
        :item-text="'name'"
        :item-value="'value'"
        :disabled="disabled"
        :rules="rules"
        :hide-details="'auto'"
      ></v-select>

      <!-- mode date-->
      <v-menu
        v-else-if="mode==='date'"
        class="mb-5"
        ref="menu"
        v-model="menu"
        :close-on-content-click="!range"
        transition="scale-transition"
        offset-y
        min-width="auto"
        color="secondary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateText"
            append-icon="mdi-calendar"
            readonly
            outlined
            dense
            full-width
            hide-details
            color="secondary"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          :active-picker.sync="activePicker"
          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
          min="1940-01-01"
          :range="range"
          @change="save"
          @input="()=>{ if ( !range ) menu = false }"
        ></v-date-picker>
      </v-menu>

      <!-- mode onlyText-->
      <div
        v-else-if="mode==='onlyText'"
        class="mb-5"
        style="width: 100%;"
      >{{ inputText }}</div>

      <btn v-if="isSend" class="ml-1" :buttonText="'sendValidateCode'" @clickBtn="send()"></btn>
      <div class="mb-5" style="min-width: 20px">{{ unit }}</div>
    </div>
  </div>
</template>
<script>
import btn from '@/components/btn.vue'
export default {
  name: 'input-block',
  props:{
    mode: {
      type: String,
      default: 'input'
    },
    column: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    title: String,
    unit: String,
    inputText: [String, Number],
    balance: Number,
    token: {
      type: String,
      default: 'eth',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return []
      }
    },
    selectItems: {
      type: Array,
      default() {
        return []
      }
    },
    isSend: {
      type: Boolean,
      default: false
    },
    range: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      text: '',
      balanceRule: (v) => parseFloat(v) < this.balance || 'Under Balance',
      menu: false,
      activePicker: null,
      date: null,
    }
  },
  watch:{
    text(newVal){
      this.$emit('update:inputText', newVal)
    },
    inputText(newVal){
      this.text = newVal
    },
    menu (val) {
      if (this.range){
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      }
    },
  },
  computed:{
    rangeText(){
      return this.text[0] ? `${this.text[0]} ~ ${this.text[1]?this.text[1]:''}` : ''
    },
    dateText: {
      get() {
        if (this.range){
          return this.rangeText
        }else{
          return this.text
        }
      },
      set(newVal){
        this.dateText = newVal
      }
    }
  },
  components:{
    btn,
  },
  methods:{
    send(){
      this.$emit('send')
    },
    save (date) {
      this.$refs.menu.save(date)
      this.text = date
    },
  },
  mounted(){
    this.text = JSON.parse(JSON.stringify(this.inputText))
  }
}
</script>
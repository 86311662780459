<template>
  <div class="deposit-confirm px-8">
    <div class="d-flex justify-space-between title-wrap mb-7">
      <div class="ml-3 d-md-block d-none">{{ $t('confirmApply') }}：</div>
      <div class="filter-block d-flex flex-column flex-sm-row align-sm-center">
        <v-text-field filled solo flat dense hide-details class="mb-4 mb-sm-0" background-color="#E9E9E9" height="28" :placeholder="$t('enterAccountSearch')" append-icon="mdi-magnify" color="secondary" v-model="searchText"></v-text-field>
        <div class="d-flex justify-sm-start justify-space-between">
          <v-btn class="ml-0 ml-sm-2" color="grey" dark depressed :width="$store.state.nowWidth>600 ? '117':'31%'" @click="filterType=0">{{ $t('all') }}</v-btn>
          <v-btn class="ml-2" color="green" dark depressed :width="$store.state.nowWidth>600 ? '117':'31%'" @click="filterType=1">{{ $t('agree') }}</v-btn>
          <v-btn class="ml-2" color="golden" dark depressed :width="$store.state.nowWidth>600 ? '117':'31%'" @click="filterType=2">{{ $t('confirmYet') }}</v-btn>
        </div>
      </div>
    </div>
    <!-- title -->
    <v-row no-gutters class="confirm-item-header mb-2 white py-4 d-md-flex d-none align-center green--text rem-1">
      <v-col class="mx-3 text-center" cols="1">{{ $t('memberAccount') }}</v-col>
      <v-col class="mx-3 text-center" cols="5">{{ $t('walletAddress') }}</v-col>
      <v-col class="mx-3 text-center" cols="1">{{ $t('applyTime') }}</v-col>
      <v-col class="mx-3 d-flex flex-column justify-center align-center" cols="1">
        <div>{{ $t('withdrawAmount') }}</div>
        <div>(TBT)</div>  
      </v-col>
      <v-col class="mx-3 text-center" cols="1">{{ $t('withdrawResult') }}</v-col>
      <v-col class="mx-3 text-center" cols="1">{{ $t('approver') }}</v-col>
    </v-row>
    <!-- content desktop -->
    <v-row no-gutters class="confirm-item mb-2 white py-4 d-md-flex d-none align-center" :class="item.status === 0 ? 'not-confirm':'confirmed'" v-for="(item, i) in filterItems" :key="i">
      <v-col class="mx-3 text-center green--text rem-0" cols="1">{{ item.phone_number }}</v-col>
      <v-col class="mx-3 text-center grey--text text--lighten-1 rem-0" cols="5">{{ item.address }}</v-col>
      <v-col class="mx-3 text-center grey--text text--lighten-1 font-9" cols="1">{{ timestampToTime(item.time * 1000) }}</v-col>
      <v-col class="mx-3 text-center red--text rem-0" cols="1">{{ item.amount }}</v-col>
      <v-col class="mx-3 text-center rem-0" cols="1">
        <div v-if="item.status === 0">
          <v-btn color="green" dark depressed @click="confirmShow=true; id=item.id">{{ $t('agree') }}</v-btn>
        </div>
        <div v-else>
          {{ $t('approved') }}
        </div>
      </v-col>
      <v-col class="mx-3 text-center grey--text text--lighten-1 font-9" cols="1">{{ item.checker }}</v-col>
    </v-row>
    <!-- content mobile -->
    <div class="confirm-item mb-2 white py-4 px-2 d-block d-md-none" :class="item.status === 0 ? 'not-confirm':'confirmed'" v-for="(item, i) in filterItems" :key="`mobile${i}`">
      <div class="d-flex justify-space-between">
        <div>
          {{ $t('memberNumber') }}：{{ item.phone_number }}
        </div>
        <div>
          <v-btn class="white--text mb-1" :color="'green'" depressed :disabled="item.status !== 0" @click="confirmShow=true; id=item.id">{{ item.status === 0 ? $t('agree') : $t('approved') }}</v-btn>
          <div class="font-8 grey--text text--lighten-1">{{ item.checker }}</div>
        </div>
      </div>
      <div class="red--text font-10 mb-1">{{ $t('withdrawAmount') }}：{{ item.amount }} TBT</div>
      <div class="grey--text text--lighten-1 font-10 mb-1">{{ $t('applyTime') }}：{{ timestampToTime(item.time * 1000) }}</div>
      <div class="grey--text text--lighten-1 font-10">{{ $t('walletAddress') }}：{{ item.address }}</div>

    </div>

    <dialogs :resetShow.sync="confirmShow" isCustom>
      <template slot="custom" class="w-full">
        <v-form ref="form" lazy-validation>
          <inputBlock class="mb-2" title="Transaction Hash" :inputText.sync="txHash" column :rules="[required]"></inputBlock>
        </v-form>
        <btn isCenter color="blue" :width="100" :buttonText="'confirm'" @clickBtn="send()"></btn>
      </template>
    </dialogs>
  </div>
</template>
<script>
import base from '@/mixin/base'
import dialogs from '@/components/admin/dialogs.vue'
import inputBlock from '@/components/admin/inputBlock.vue'
import btn from '@/components/btn.vue'
export default {
  mixins: [base],
  data(){
    return{
      items: [],
      filterItems: [],
      searchItems: [],
      filterType: 0,
      searchText: '',
      confirmShow: false,
      id: '',
      txHash: '',
    }
  },
  components:{
    dialogs,
    inputBlock,
    btn
  },
  watch:{
    confirmShow(newVal){
      if (!newVal){
        this.id = ''
        this.$refs.form.reset()
      }
    },
    filterType(newVal){
      console.log('newVal', newVal)
      this.searchItems = this.items.filter(item => item.phone_number.includes(this.searchText))
      if (newVal === 0){
        this.filterItems = [...this.searchItems]
      }else if (newVal === 1){
        this.filterItems = this.searchItems.filter(item => item.status === 1)
      }else if (newVal === 2){
        this.filterItems = this.searchItems.filter(item => item.status === 0)
      }
    },
    searchText(newVal){
      this.searchItems = this.items.filter(item => item.phone_number.includes(newVal))
      this.filterItems = [...this.searchItems]
      this.filterType = 0
    }
  },
  methods:{
    async send(){
      if (this.$refs.form.validate()){
        let data = {
          id: this.id,
          tx: this.txHash
        }
        console.log('data', data)
        try{
          let result = await this.$store.dispatch('confirmDeposit', data)
          console.log('result', result)
          if (result.status === 200){
            this.$toasted.show(this.$t('confirmSuccess'))
            this.confirmShow = false
            this.$refs.form.reset()
            await this.getDepositList()
          }else{
            this.$toasted.error(this.$t('confirmFail'))
          }
        }catch(error){
          this.$toasted.error(this.$t('confirmFail'))
        }
      }
    },
    async getDepositList(){
      try{
        let result = await this.$store.dispatch('depositList')
        console.log('result', result)
        if (result.status === 200){
          result.data.map(item => item.phone_number = `0${item.phone_number}`)
          this.items = result.data
        }else{
          this.items = []
        }
      }catch(error){
        this.items = []
      }
    }
  },
  async mounted(){
    await this.getDepositList()
    this.filterItems = [...this.items]
  }
}
</script>
<style lang="scss">
.deposit-confirm{
  .title-wrap{
    margin-top: 96px;
    @include dai_vuetify_md{
      margin-top: 25px;
    }
  }
  .filter-block{
    @include dai_vuetify_md{
      width: 100%;
    }
  }
  .confirm-item-header{
    box-shadow: 0 0 2px rgba(0, 0, 0, .25);
    position: relative;
    &::before{
      position: absolute;
      left: -9px;
      content:"";
      width: 9px;
      height: 100%;
      background: #289556;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .confirm-item{
    position: relative;
    &::before{
      position: absolute;
      top: 0;
      left: -9px;
      content:"";
      width: 9px;
      height: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .not-confirm{
    &::before{
      background: #D78729;
    }
  }
  .confirmed{
    &::before{
      background: #289556;
    }
  }
}
</style>